import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CustomLink } from '../../utils/navigation';

export const ServiceFeaures = ({ features = [] }) => {
  if (features.length === 0) return null;

  return (
    <div className="choose_Accordian_Wdt">
      <div className="accordion-first clearfix acord_mar_non">
        <div className="accordion" id="accordion2">
          {features.map(({ id, title, description }) => (
            <div key={id} className="accordion-group">
              <div className="accordion-heading">
                <CustomLink className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" to={`#${id}`}>
                  <em className="icon-fixed-width fa fa-plus"></em><FormattedMessage id={title} />
                </CustomLink> 
              </div>
              <div id={id} className="accordion-body collapse">
                <div className="accordion-inner text-justify">
                  <FormattedMessage id={description} tagName="p"/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
