import React from 'react';
import PropTypes from 'prop-types';

// Own components
import Layout from '../components/Layout/es';
import { Service } from '../containers/Service';

// Assets & Misc
import { getQueryParam } from '../utils/getters';
import { services } from '../constants/services';
import { navigateTo } from '../utils/navigation';

const ServicePage = () => {
  const serviceId = getQueryParam('id');
  const service = services.find(({ id }) => id === serviceId);

  if (!service) {
    navigateTo('/404');
    return null;
  }

  return (
    <Layout>
      <Service {...service} />
    </Layout>
  );
};

ServicePage.propTypes  = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired
};

export default ServicePage;