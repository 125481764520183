import React from 'react';
import { useIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Assets & Misc
import { services } from '../../constants/services';

// Own component
import SEO from '../../components/seo';
import { CustomLink } from '../../utils/navigation';
import { useLanguages } from '../../hooks/useLanguages';
import { ServiceFeaures } from '../../components/ServiceFeatures';
import SectionCover from '../../components/SectionCover/sectionCover';

export const Service = ({ id, title, description, image, features = [] }) => {
  const { formatMessage } = useIntl();
  const { path } = useLanguages();

  return (
    <>
      <SEO title={formatMessage({ id: title })} />
      <SectionCover title={formatMessage({ id: title })} src="/images/services/services-cover.jpeg" />
      <section className="pad100-top-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-5 col-xs-12 left-column">
              <ul className="category-list">
                {services.map(service => (
                  <li key={`li-${service.id}`}>
                    <CustomLink to={`${path}service?id=${service.id}`} className={service.id === id ? 'active-category' : ''}><FormattedMessage id={service.title}/></CustomLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-8 right-column pull-right">
              <div className="service-right-desc" style={{ margin: 0 }}>
                <span className="image_hover ">
                  <img src={image} className="img-responsive zoom_img_effect" alt={title} />
                </span>
                <p className="text-justify">
                  <FormattedHTMLMessage id={description} />
                </p>
              </div>
              <div className="service-detail">
                <ServiceFeaures features={features} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
